import React from "react";

import Alert from "@material-ui/lab/Alert";
import Christmas from "../assets/img/christmas.jpg";

const Promo = () => {
  return (
    <div>
      <Alert variant="filled" elevation={6} severity="warning" className="my-4">
        <p>Beste klanten, </p>
        <br></br>
        <p>
          Wij zijn gesloten van <strong>24/12/2021 t.e.m. 27/12/2021</strong>{" "}
          <br></br>
          en van <strong>31/12/2021 t.e.m. 03/01/2022</strong>
        </p>
        <br></br>
        <p>Wij wensen jullie alvast fijne feestdagen!</p>
        <br></br>
        <p>Groetjes,</p>
        <p>Team Den Baba</p>
      </Alert>
      <div className="flex flex-col justify-center items-center my-4">
        <img src={Christmas} alt="Den Baba Kerst" />
      </div>
    </div>
  );
};

export default Promo;
