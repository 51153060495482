import React from "react";
import { Paper, IconButton } from "@material-ui/core";
import { connect } from "react-redux";

import TrashIcon from "react-feather/dist/icons/x";
import { bindActionCreators } from "redux";
import { removeProduct } from "../store/cart/actions";

const CartContent = (props) => {
  const { items, editable, removeItem, orderItems = items } = props;
  return orderItems
    .sort((a, b) => {
      if (a.printOrder > b.printOrder) {
        return 1;
      }
      if (b.printOrder > a.printOrder) {
        return -1;
      }
      return 0;
    })
    .map((it) => (
      <Paper
        className="flex flex-row justify-between items-center p-4 mb-4"
        key={it.id}
        elevation={4}>
        <div className="flex flex-col flex-grow pr-2">
          <div className="flex flex-row items-center justify-between mb-2">
            <p className="font-hairline">{it.qty}x</p>
            <span className="text-right">
              <p>{`${it.name} ${it.size !== null ? `(${it.size})` : ""}`}</p>
              <ul className="pl-4">
                {it.extras.map((e) => (
                  <li key={e.id} className="font-hairline text-sm text-right">
                    + {e.name}
                  </li>
                ))}
              </ul>
            </span>
          </div>
          <p className="text-right font-thin">
            {((it.price * it.qty) / 100).toFixed(2).replace(".", ",")} &euro;
          </p>
        </div>
        <div className={`${editable ? "flex-grow-0" : "flex-0"}`}>
          {editable && (
            <IconButton
              size="small"
              color="secondary"
              onClick={() => removeItem(it.id)}>
              <TrashIcon />
            </IconButton>
          )}
        </div>
      </Paper>
    ));
};

const mapStateToProps = (state) => ({
  ...state.cart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeItem: removeProduct,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CartContent);
